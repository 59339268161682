let primaryImageOfPage = ''


/**
 * Updates the SEO metadata based on the provided data and schema image.
 * @param {Object} data - The data object containing the SEO information.
 * @param {string} schemaImage - The URL of the schema image.
 * @returns {Object} - An empty object.
 */
export default (data, schemaImage = '') => {
  const seo = data.value?.yoast_head_json

  // console.log('Yoast SEO before : ', seo)
  // console.log('Yoast Schema before : ', seo.schema)
  if (seo) {
    const newSeo = {}
    let canonicalValue = ''
    let articleAuthorValue = ''
    let ogImageFound = false

    Object.entries(seo).forEach(([key, value]) => {
      // COMMENT: Rewrite "og" tags from Yoast...
      if (key.indexOf('og_') > -1) {
        // if (key.indexOf('og_') > -1) {
        const newKey = key.replace('og_', 'og:')
        newSeo[newKey] = value
        // seo[key] = null // remove old key

        if (key.indexOf('og_image') > -1) {
          ogImageFound = true
        }
      }
      if (key.indexOf('twitter_') > -1) {
        const newKey = key.replaceAll('_', ':')
        newSeo[newKey] = value
        // seo[key] = null // remove old key
      }
      if (key.indexOf('robots') > -1) {
        newSeo.robots = ''
        if (typeof value !== 'string') {
          Object.entries(value).forEach(([rkey, rvalue]) => {
            newSeo.robots += `${rvalue},`
          })
        } else {
          newSeo.robots = seo.robots
        }
      }
      if (key.indexOf('canonical') > -1) {
        if(value.indexOf('http://') !== -1) {
          canonicalValue = value.replace('http://', 'https://');
        }
        else {
          canonicalValue = value
        }
      }
      // if (key.indexOf('description') > -1) {
      //   newSeo['description'] = value
      // }
      if (key.indexOf('article_modified_time') > -1) {
        newSeo['article:modified_time'] = value
      }
      if (key.indexOf('article_published_time') > -1) {
        newSeo['article:published_time'] = value
      }
      if (key.indexOf('article_author') > -1) {
        articleAuthorValue = value
      }
    });

    newSeo.title = seo.title
    newSeo.description = seo.description && seo.description !== '' ? seo.description : ''


    if (canonicalValue === '') {
      // TODO: Set current url as canonical ( but validate that it is necessary, doesn't seem to be...)
    }
    newSeo['article:author'] = articleAuthorValue && articleAuthorValue !== '' ? articleAuthorValue : seo.og_site_name
    newSeo['og:locale'] = 'en' // is to be used later on
    newSeo['og:type'] = 'website' // is to be used later on

    if (!ogImageFound) {
      // COMMENT: Set "og:image" if not already pressent in data
      // ... either from Hero image or Billboard image

      // Set "og:image" either from Hero image or Billboard image
      const hero = data.value?.acf?.page_modules?.find((module) => module.acf_fc_layout === 'hero');
      if (hero && hero?.items?.length && hero?.items[0]) {
        const media = hero?.items[0]?.media;
        // console.log('Hero media:', media);
        // Set poster, if available, before testing for image
        if (media?.video && media?.video?.poster_image) {
          newSeo['og:image'] = media?.video?.poster_image.url;
          // other image properties...
          primaryImageOfPage = media?.video?.poster_image.url;
          // console.log('primaryImageOfPage from video: ', primaryImageOfPage);
        } else if (media.image) {
          newSeo['og:image'] = media?.image?.url;
          // other image properties...
          primaryImageOfPage = media?.image?.url;
          // console.log('primaryImageOfPage from image: ', primaryImageOfPage);
        }
      } else if (!hero) {
        const billboard = data.value?.acf?.page_modules?.find((module) => module.acf_fc_layout === 'billboard');
        if (billboard) {
          const media = billboard.media;
          // console.log('Billboard media:', media);
          if (media?.video && media?.video?.poster_image) {
            newSeo['og:image'] = media?.video?.poster_image.url;
            // other image properties...
            primaryImageOfPage = media?.video?.poster_image.url;
          } else if (media.image) {
            newSeo['og:image'] = media?.image?.url;
            // other image properties...
            primaryImageOfPage = media?.image?.url;
          }
        }
      }


      // console.log('og:image after update: ', newSeo['og:image']);
      // console.log('primaryImageOfPage after update: ', primaryImageOfPage);


    }
    const runtimeConfig = useRuntimeConfig()
    useSeoMeta(newSeo)
    useServerSeoMeta(newSeo)
    useHead({
      link: [
        {
          rel: 'canonical',
          href: canonicalValue,
        },
      ],
      templateParams: {
        schemaOrg: {
          host: runtimeConfig.public.host,
          // path: '/blog'
          trailingSlash: true,
        }
      }
    })

    if (schemaImage !== '') {
      primaryImageOfPage = schemaImage
    }


    // console.log('Yoast SEO after: ', seo)
    // console.log('Yoast NewSeo after: ', newSeo)

    // console.log('schemaImage: ', schemaImage)
    // console.log('primaryImageOfPage: ', primaryImageOfPage)

    useSchemaOrg([
      // defineOrganization(),

      // defineWebSite({
      //   name: 'My test',
      // }),

      defineWebPage({
        primaryImageOfPage,
        // potentialAction: []
      }),
    ])
  }

  return {}
}
